/* eslint-disable no-underscore-dangle */
import { ProjectIs } from 'src/app/models/projects.model';
import { MenuItem } from '../app-base/services/menu.service';
import { RootModuleType } from './models/root-module.model';
import { UserIs, UserTags } from './models/user-is.model';
import { CMS_BASE_PATH, CM_BASE_PATH } from './shared/app.const';
import { SupportModalComponent } from './cms/modules/help/modals/support-modal/support-modal.component';
import { MenuCallback } from './models/menu.model';

export enum MenuType {
  CLIENTS = 'CLIENTS',
  PERSONNEL = 'PERSONNEL',
  CLIENT_DOCUMENTS = 'CLIENT_DOCUMENTS',
  CLIENT_REPORTS = 'CLIENT_REPORTS',
  CONTRACTS_AND_RENEWALS = 'CONTRACTS_AND_RENEWALS',
  INVOICES = 'INVOICES',
  INVOICES_PROCESS_INVOICES = 'INVOICES_PROCESS_INVOICES',
  INVOICES_INVOICES_TO_PULL = 'INVOICES_INVOICES_TO_PULL',
  INVOICES_CREDIT_INVOICES = 'INVOICES_CREDIT_INVOICES',
  INVOICES_INVOICE_STATUS = 'INVOICES_INVOICE_STATUS',
  STATEMENTS = 'STATEMENTS',
  STATEMENTS_PROCESS_STATEMENTS = 'STATEMENTS_PROCESS_STATEMENTS',
  STATEMENTS_STATEMENTS_TO_PULL = 'STATEMENTS_STATEMENTS_TO_PULL',
  STATEMENTS_STATEMENT_STATUS = 'STATEMENTS_STATEMENT_STATUS',
  PAYMENTS = 'PAYMENTS',
  PAYMENTS_ENTER_PAYMENTS = 'PAYMENTS_ENTER_PAYMENTS',
  PAYMENTS_ADJUST_ACCOUNTS = 'PAYMENTS_ADJUST_ACCOUNTS',
  BILLING_REPORTS = 'BILLING_REPORTS',
  DASHBOARD = 'DASHBOARD',
  EMPLOYERS = 'EMPLOYERS',
  CASES = 'CASES',
  SIDES = 'SIDES',
  SIDES_CONNECTORS = 'SIDES_CONNECTORS',
  SIDES_SEARCH = 'SIDES_SEARCH',
  BENEFIT_CHARGES = 'BENEFIT_CHARGES',
  PROTESTS = 'PROTESTS',
  STATEMENTS_OF_ACCOUNT = 'STATEMENTS_OF_ACCOUNT',
  RATE_NOTICES = 'RATE_NOTICES',
  DOCUMENTS = 'DOCUMENTS',
  ACTIVITIES = 'ACTIVITIES',
  ACTIVITIES_BENEFIT_CHARGES = 'ACTIVITIES_BENEFIT_CHARGES',
  ACTIVITIES_PARTNER_PROGRAM_MONTHLY_EMAIL = 'ACTIVITIES_PARTNER_PROGRAM_MONTHLY_EMAIL',
  ACTIVITIES_CHARGE_MGMT = 'ACTIVITIES_CHARGE_MGMT',
  ACTIVITIES_DUA_IMPORT = 'ACTIVITIES_DUA_IMPORT',
  ACTIVITIES_RATE_NOTICES = 'ACTIVITIES_RATE_NOTICES',
  ACTIVITIES_MISSING_RATE_NOTICES = 'ACTIVITIES_MISSING_RATE_NOTICES',
  ACTIVITIES_EMAIL_NOTIFICATION = 'ACTIVITIES_EMAIL_NOTIFICATION',
  ACTIVITIES_MA_RATE_NOTICES = 'ACTIVITIES_MA_RATE_NOTICES',
  ACTIVITIES_MAILING_LABELS = 'ACTIVITIES_MAILING_LABELS',
  REPORTS = 'REPORTS',
  REPORTS_UI_ACTIVITY_REPORT = 'REPORTS_UI_ACTIVITY_REPORT',
  REPORTS_RATE_NOTICES = 'REPORTS_RATE_NOTICES',
  REPORTS_BENEFIT_CHARGES = 'REPORTS_BENEFIT_CHARGES',
  REPORTS_PARTNER_PROGRAMS = 'REPORTS_PARTNER_PROGRAMS',
  REPORTS_ANNUAL_PAGES = 'REPORTS_ANNUAL_PAGES',
  BILLING = 'BILLING',
  ANALYTICS = 'ANALYTICS',
  ANALYTICS_ANALYST_PERFORMANCE = 'ANALYTICS_ANALYST_PERFORMANCE',
  SETTINGS = 'SETTINGS',
  SETTINGS_DATA = 'SETTINGS_DATA',
  SETTINGS_DATA_STATES = 'SETTINGS_DATA_STATES',
  SETTINGS_DATA_REASON_CODES = 'SETTINGS_DATA_REASON_CODES',
  SETTINGS_DATA_HEARINGS = 'SETTINGS_DATA_HEARINGS',
  SETTINGS_INTERNAL_USERS = 'SETTINGS_INTERNAL_USERS',
  SETTINGS_ANALYSTS_ASSIGNED = 'SETTINGS_ANALYSTS_ASSIGNED',
  SETTINGS_PARTNER_PROGRAMS = 'SETTINGS_PARTNER_PROGRAMS',
  SETTINGS_AUTHORIZE_ACCESS = 'SETTINGS_AUTHORIZE_ACCESS',
  TOOLS = 'TOOLS',
  TOOLS_OCR = 'TOOLS_OCR',
  HELP = 'HELP',
  ABOUT = 'ABOUT',
}

export const menuItems: MenuItem[] = [
  {
    text: 'Clients',
    route: `${CM_BASE_PATH}/clients`,
    materialIcon: 'business_center',
    activeBy: [
      {
        path: `${CM_BASE_PATH}/clients`,
        afterPaths: [],
      },
      {
        path: `${CM_BASE_PATH}/client/`,
        afterPaths: [],
      },
    ],
    rootModule: RootModuleType.CM,
    type: MenuType.CLIENTS,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.CM.CLIENT_MGR, UserIs.CM.CLIENT_VIEW_ONLY],
      [ProjectIs.STOP_CLAIMS]: [UserIs.CM.CLIENT_MGR, UserIs.CM.CLIENT_VIEW_ONLY],
      [ProjectIs.ACME]: [UserIs.CM.CLIENT_MGR, UserIs.CM.CLIENT_VIEW_ONLY],
    },
  },
  {
    text: 'Personnel',
    route: `${CM_BASE_PATH}/personnel`,
    materialIcon: 'supervisor_account',
    activeBy: [
      {
        path: `${CM_BASE_PATH}/personnel`,
        afterPaths: [],
      },
      {
        path: `${CM_BASE_PATH}/person`,
        afterPaths: [],
      },
    ],
    rootModule: RootModuleType.CM,
    type: MenuType.PERSONNEL,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.CM.CLIENT_MGR, UserIs.CM.CLIENT_VIEW_ONLY],
      [ProjectIs.STOP_CLAIMS]: [UserIs.CM.CLIENT_MGR, UserIs.CM.CLIENT_VIEW_ONLY],
      [ProjectIs.ACME]: [UserIs.CM.CLIENT_MGR, UserIs.CM.CLIENT_VIEW_ONLY],
    },
  },
  {
    text: 'Client Documents',
    route: `${CM_BASE_PATH}/client-documents`,
    materialIcon: 'folder',
    activeBy: [
      {
        path: `${CM_BASE_PATH}/client-documents`,
        afterPaths: [], // any path
      },
    ],
    rootModule: RootModuleType.CM,
    type: MenuType.CLIENT_DOCUMENTS,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.CM.CLIENT_MGR, UserIs.CM.CLIENT_VIEW_ONLY],
      [ProjectIs.STOP_CLAIMS]: [UserIs.CM.CLIENT_MGR, UserIs.CM.CLIENT_VIEW_ONLY],
      [ProjectIs.ACME]: [UserIs.CM.CLIENT_MGR, UserIs.CM.CLIENT_VIEW_ONLY],
    },
  },
  {
    text: 'Client Reports',
    route: `${CM_BASE_PATH}/client-reports`,
    materialIcon: 'assignment_ind',
    activeLinks: ['client-reports'],
    rootModule: RootModuleType.CM,
    type: MenuType.CLIENT_REPORTS,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.CM.BILL_MGR],
    },
  },
  {
    text: 'Contracts & Renewals',
    route: `${CM_BASE_PATH}/contract-and-renewals`,
    materialIcon: 'assignment_turned_in',
    activeLinks: ['contract-and-renewals'],
    rootModule: RootModuleType.CM,
    type: MenuType.CONTRACTS_AND_RENEWALS,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.CM.BILL_MGR],
    },
  },
  {
    text: 'Invoices',
    materialIcon: 'request_quote',
    activeLinks: ['invoices'],
    rootModule: RootModuleType.CM,
    type: MenuType.INVOICES,
    subMenu: [
      {
        text: 'Process Invoices',
        route: `${CM_BASE_PATH}/invoices/process-invoices`,
        materialIcon: 'request_quote',
        type: MenuType.INVOICES_PROCESS_INVOICES,
      },
      {
        text: 'Invoices to Pull',
        route: `${CM_BASE_PATH}/invoices/to-pull`,
        materialIcon: 'request_quote',
        type: MenuType.INVOICES_INVOICES_TO_PULL,
      },
      {
        text: 'Credit Invoices',
        route: `${CM_BASE_PATH}/invoices/credit`,
        materialIcon: 'request_quote',
        type: MenuType.INVOICES_CREDIT_INVOICES,
      },
      {
        text: 'Invoice Status',
        route: `${CM_BASE_PATH}/invoices/invoice-statuses`,
        materialIcon: 'request_quote',
        type: MenuType.INVOICES_INVOICE_STATUS,
      },
    ],
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.CM.TLE],
    },
  },
  {
    text: 'Statements',
    materialIcon: 'attach_money',
    activeLinks: ['statements'],
    rootModule: RootModuleType.CM,
    type: MenuType.STATEMENTS,
    subMenu: [
      {
        text: 'Process Statements',
        route: `${CM_BASE_PATH}/statements/process-statements`,
        materialIcon: 'attach_money',
        type: MenuType.STATEMENTS_PROCESS_STATEMENTS,
      },
      {
        text: 'Statements to Pull',
        route: `${CM_BASE_PATH}/statements/to-pull`,
        materialIcon: 'attach_money',
        type: MenuType.STATEMENTS_STATEMENTS_TO_PULL,
      },
      {
        text: 'Statement Status',
        route: `${CM_BASE_PATH}/statements/statement-statuses`,
        materialIcon: 'attach_money',
        type: MenuType.STATEMENTS_STATEMENT_STATUS,
      },
    ],
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.CM.TLE],
    },
  },
  {
    text: 'Payments',
    materialIcon: 'payment',
    activeLinks: ['payments', 'adjustments'],
    rootModule: RootModuleType.CM,
    type: MenuType.PAYMENTS,
    subMenu: [
      {
        text: 'Enter Payments',
        route: `${CM_BASE_PATH}/payments`,
        materialIcon: 'payment',
        activeLinks: ['payments'],
        type: MenuType.PAYMENTS_ENTER_PAYMENTS,
      },
      {
        text: 'Adjust Accounts',
        route: `${CM_BASE_PATH}/adjustments`,
        materialIcon: 'payment',
        activeLinks: ['adjustments'],
        type: MenuType.PAYMENTS_ADJUST_ACCOUNTS,
      },
    ],
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.CM.TLE],
    },
  },
  {
    text: 'Billing Reports',
    route: `${CM_BASE_PATH}/billing-reports`,
    materialIcon: 'assignment_turned_in',
    activeLinks: ['billing-reports'],
    rootModule: RootModuleType.CM,
    type: MenuType.BILLING_REPORTS,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.CM.TLE, UserIs.CM.BILL_MGR],
    },
  },
  // CMS
  {
    text: 'dashboard',
    route: `${CMS_BASE_PATH}`,
    materialIcon: 'equalizer',
    activeBy: [
      {
        path: `${CMS_BASE_PATH}`,
        afterPaths: ['', 'actionitems'],
      },
    ],
    rootModule: RootModuleType.CMS,
    type: MenuType.DASHBOARD,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.CLIENT, UserIs.INTERNAL],
      [ProjectIs.STOP_CLAIMS]: [UserIs.CLIENT, UserIs.INTERNAL],
      [ProjectIs.ACME]: [UserIs.CLIENT, UserIs.INTERNAL],
    },
  },
  {
    text: 'Employers',
    route: `${CMS_BASE_PATH}/clients`,
    materialIcon: 'group',
    activeLinks: [`clients`],
    rootModule: RootModuleType.CMS,
    type: MenuType.EMPLOYERS,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL],
    },
  },
  {
    text: 'Cases',
    route: `${CMS_BASE_PATH}/cases`,
    materialIcon: 'sms_failed',
    activeLinks: [`cases`],
    rootModule: RootModuleType.CMS,
    type: MenuType.CASES,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL, UserIs.CLIENT.AM, UserIs.CLIENT.GC, UserIs.PARTNER],
      [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL, UserIs.CLIENT.AM, UserIs.CLIENT.GC, UserIs.PARTNER],
      [ProjectIs.ACME]: [UserIs.INTERNAL, UserIs.CLIENT.AM, UserIs.CLIENT.GC, UserIs.PARTNER],
    },
  },
  {
    text: 'SIDES',
    materialIcon: 'cloud',
    activeLinks: ['sides'],
    rootModule: RootModuleType.CMS,
    type: MenuType.SIDES,
    subMenu: [
      {
        text: 'Connectors',
        route: `${CMS_BASE_PATH}/sides/connectors`,
        materialIcon: 'cast_connected',
        type: MenuType.SIDES_CONNECTORS,
      },
      {
        text: 'Search',
        route: `${CMS_BASE_PATH}/sides/search`,
        materialIcon: 'search',
        type: MenuType.SIDES_SEARCH,
      },
    ],
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL.ADMIN],
      [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL.ADMIN],
      [ProjectIs.ACME]: [UserIs.INTERNAL.ADMIN],
    },
  },
  {
    text: 'Benefit Charges',
    route: `${CMS_BASE_PATH}/benefitcharges`,
    materialIcon: 'verified_user',
    activeLinks: ['benefitcharges'],
    rootModule: RootModuleType.CMS,
    type: MenuType.BENEFIT_CHARGES,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL, UserIs.PARTNER],
      [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL, UserIs.PARTNER],
      [ProjectIs.ACME]: [UserIs.INTERNAL, UserIs.PARTNER],
    },
  },
  {
    text: 'Protests',
    route: `${CMS_BASE_PATH}/protests`,
    materialIcon: 'library_books',
    activeBy: [
      {
        path: `${CMS_BASE_PATH}/protests`,
        afterPaths: [], // any path
      },
    ],
    rootModule: RootModuleType.CMS,
    type: MenuType.PROTESTS,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL],
    },
  },
  {
    text: 'Statements of Account',
    route: `${CMS_BASE_PATH}/statements-of-account`,
    materialIcon: 'description',
    activeLinks: ['statements-of-account'],
    rootModule: RootModuleType.CMS,
    type: MenuType.STATEMENTS_OF_ACCOUNT,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL, UserIs.PARTNER],
    },
  },
  {
    text: 'Rate Notices',
    route: `${CMS_BASE_PATH}/rate-notices`,
    materialIcon: 'event_note',
    activeBy: [
      {
        path: `${CMS_BASE_PATH}/rate-notices`,
        afterPaths: [],
      },
    ],
    rootModule: RootModuleType.CMS,
    type: MenuType.RATE_NOTICES,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL],
    },
  },
  {
    text: 'Documents',
    route: `${CMS_BASE_PATH}/documents`,
    materialIcon: 'folder',
    activeBy: [
      {
        path: `${CMS_BASE_PATH}/documents`,
        afterPaths: [], // any path
      },
    ],
    rootModule: RootModuleType.CMS,
    type: MenuType.DOCUMENTS,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL, UserIs.CLIENT],
      [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL, UserIs.CLIENT],
      [ProjectIs.ACME]: [UserIs.INTERNAL, UserIs.CLIENT],
    },
  },
  {
    text: 'Activities',
    materialIcon: 'view_list',
    activeLinks: ['activities'],
    rootModule: RootModuleType.CMS,
    type: MenuType.ACTIVITIES,
    subMenu: [
      {
        text: 'Benefit Charges',
        materialIcon: 'widgets',
        cssClass: 'text-nowrap',
        subMenu: [
          {
            text: 'Partner Program Monthly Email',
            route: `${CMS_BASE_PATH}/activities/partner-program-monthly-report`,
            materialIcon: 'import_export',
            type: MenuType.ACTIVITIES_PARTNER_PROGRAM_MONTHLY_EMAIL,
          },
        ],
        type: MenuType.ACTIVITIES_BENEFIT_CHARGES,
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL.ADMIN],
        },
      },
      {
        text: 'Charge Mgmt',
        materialIcon: 'widgets',
        type: MenuType.ACTIVITIES_CHARGE_MGMT,
        subMenu: [
          {
            text: 'DUA Import',
            route: `${CMS_BASE_PATH}/activities/dua-import`,
            materialIcon: 'import_export',
            type: MenuType.ACTIVITIES_DUA_IMPORT,
          },
        ],
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL],
        },
      },
      {
        text: 'Rate Notices',
        materialIcon: 'event_note',
        type: MenuType.ACTIVITIES_RATE_NOTICES,
        subMenu: [
          {
            text: 'Missing Rate Notices',
            route: `${CMS_BASE_PATH}/activities/missing-rn`,
            materialIcon: 'notifications',
            type: MenuType.ACTIVITIES_MISSING_RATE_NOTICES,
          },
          {
            text: 'Email Notification',
            route: `${CMS_BASE_PATH}/activities/email-notification`,
            materialIcon: 'email',
            type: MenuType.ACTIVITIES_EMAIL_NOTIFICATION,
          },
          {
            text: 'MA Rate Notices',
            route: `${CMS_BASE_PATH}/activities/ma-email-notification`,
            materialIcon: 'event_note',
            type: MenuType.ACTIVITIES_MA_RATE_NOTICES,
          },
        ],
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL],
        },
      },
      {
        text: 'Mailing Labels',
        route: `${CMS_BASE_PATH}/activities/mailing-labels`,
        materialIcon: 'email',
        type: MenuType.ACTIVITIES_MAILING_LABELS,
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL],
        },
      },
    ],
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL.ADMIN, UserIs.INTERNAL.ANALYST],
    },
  },
  {
    text: 'Reports',
    materialIcon: 'assignment_turned_in',
    activeLinks: ['reports'],
    rootModule: RootModuleType.CMS,
    type: MenuType.REPORTS,
    subMenu: [
      {
        text: 'UI Activity Report',
        route: `${CMS_BASE_PATH}/reports/activity-report`,
        materialIcon: 'event_note',
        type: MenuType.REPORTS_UI_ACTIVITY_REPORT,
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL, UserIs.CLIENT],
          [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL, UserIs.CLIENT],
          [ProjectIs.ACME]: [UserIs.INTERNAL, UserIs.CLIENT],
        },
      },
      {
        text: 'Rate Notices',
        route: `${CMS_BASE_PATH}/reports/rate-notices`,
        materialIcon: 'event_note',
        type: MenuType.REPORTS_RATE_NOTICES,
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL],
        },
      },
      {
        text: 'Benefit Charges',
        route: `${CMS_BASE_PATH}/reports/benefit-charges`,
        materialIcon: 'event_note',
        type: MenuType.REPORTS_BENEFIT_CHARGES,
        accessRules: {
          [ProjectIs.UTMC]: [],
          [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL, UserIs.PARTNER],
          [ProjectIs.ACME]: [UserIs.INTERNAL, UserIs.PARTNER],
        },
      },
      {
        text: 'Partner Programs',
        route: `${CMS_BASE_PATH}/reports/partner-programs`,
        materialIcon: 'event_note',
        type: MenuType.REPORTS_PARTNER_PROGRAMS,
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL, UserIs.PARTNER],
          [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL, UserIs.PARTNER],
          [ProjectIs.ACME]: [UserIs.INTERNAL, UserIs.PARTNER],
        },
      },
      {
        text: 'Annual Pages',
        route: `${CMS_BASE_PATH}/reports/annual-pages`,
        materialIcon: 'event_note',
        type: MenuType.REPORTS_ANNUAL_PAGES,
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL, UserIs.CLIENT.GC, UserIs.CLIENT.AM]
        },
      },
    ],
    accessRules: [ProjectIs.UTMC, ProjectIs.STOP_CLAIMS, ProjectIs.ACME],
  },
  {
    text: 'Billing',
    route: `${CMS_BASE_PATH}/billing`,
    materialIcon: 'request_quote',
    activeLinks: ['billing'],
    rootModule: RootModuleType.CMS,
    type: MenuType.BILLING,
    accessRules: {
      [ProjectIs.UTMC]: [
        {
          roles: [UserIs.INTERNAL.ANALYST],
          tags: []
        },
        {
          roles: [UserIs.CLIENT],
          tags: [UserTags.BILL, UserTags.BILL_],
        }
      ]
    },
  },
  {
    text: 'Analytics',
    materialIcon: 'assignment_turned_in',
    subMenu: [
      {
        text: 'Analyst Performance',
        route: `${CMS_BASE_PATH}/analytics/analyst-performance`,
        materialIcon: 'event_note',
        type: MenuType.ANALYTICS_ANALYST_PERFORMANCE,
      },
    ],
    activeLinks: ['analytics'],
    rootModule: RootModuleType.CMS,
    type: MenuType.ANALYTICS,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL.ADMIN]
    },
  },
  {
    text: 'Settings',
    materialIcon: 'settings',
    activeBy: [
      {
        path: `${CMS_BASE_PATH}`,
        afterPaths: ['users', 'analysts', 'partner-programs'],
        doesNotInclude: 'reports', // after path does not include this part
      },
      {
        path: `states`,
        afterPaths: [],
      },
      {
        path: `reason-codes`,
        afterPaths: [],
      },
      {
        path: `hearings`,
        afterPaths: ['affiliates', 'location'],
      },
    ],
    rootModule: RootModuleType.CMS,
    type: MenuType.SETTINGS,
    subMenu: [
      {
        text: 'Internal Users',
        route: `${CMS_BASE_PATH}/users`,
        materialIcon: 'group',
        type: MenuType.SETTINGS_INTERNAL_USERS,
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL.ADMIN],
        },
      },
      {
        text: 'Analysts Assigned',
        route: `${CMS_BASE_PATH}/analysts`,
        materialIcon: 'account_circle',
        type: MenuType.SETTINGS_ANALYSTS_ASSIGNED,
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL.ADMIN],
          [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL.ADMIN],
          [ProjectIs.ACME]: [UserIs.INTERNAL.ADMIN],
        },
      },
      {
        text: 'Partner Programs',
        route: `${CMS_BASE_PATH}/partner-programs`,
        materialIcon: 'work',
        type: MenuType.SETTINGS_PARTNER_PROGRAMS,
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL.ADMIN],
          [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL.ADMIN],
          [ProjectIs.ACME]: [UserIs.INTERNAL.ADMIN],
        },
      },
      {
        text: 'Authorize Access',
        route: `${CMS_BASE_PATH}/auth-access`,
        materialIcon: 'security',
        type: MenuType.SETTINGS_AUTHORIZE_ACCESS,
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.CLIENT.AM],
          [ProjectIs.STOP_CLAIMS]: [UserIs.CLIENT.AM],
          [ProjectIs.ACME]: [UserIs.CLIENT.AM],
        },
      },
      {
        text: 'Data',
        materialIcon: 'assignment',
        type: MenuType.SETTINGS_DATA,
        subMenu: [
          {
            text: 'States',
            route: `${CMS_BASE_PATH}/states`,
            materialIcon: 'flag',
            type: MenuType.SETTINGS_DATA_STATES,
          },
          {
            text: 'Reason Codes',
            route: `${CMS_BASE_PATH}/reason-codes/separationcodemapping`,
            materialIcon: 'label',
            type: MenuType.SETTINGS_DATA_REASON_CODES,
          },
          {
            text: 'Hearings',
            route: `${CMS_BASE_PATH}/hearings/affiliates`,
            materialIcon: 'work',
            type: MenuType.SETTINGS_DATA_HEARINGS,
          },
        ],
        accessRules: {
          [ProjectIs.UTMC]: [UserIs.INTERNAL.ADMIN],
        },
      },
    ],
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL.ADMIN, UserIs.CLIENT.AM],
      [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL.ADMIN, UserIs.CLIENT.AM],
      [ProjectIs.ACME]: [UserIs.INTERNAL.ADMIN, UserIs.CLIENT.AM],
    },
  },
  {
    text: 'Tools',
    materialIcon: 'work',
    rootModule: RootModuleType.CMS,
    type: MenuType.TOOLS,
    subMenu: [
      {
        text: 'OCR Gateway',
        materialIcon: 'select_all',
        type: MenuType.TOOLS_OCR,
        callback: MenuCallback.OCRG_LINK,
      },
    ],
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL],
      [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL],
      [ProjectIs.ACME]: [UserIs.INTERNAL],
    },
  },
  {
    text: 'Help',
    materialIcon: 'live_help',
    modal: {
      component: SupportModalComponent,
      config: {
        id: 'supportModal',
        maxWidth: '600px',
        minWidth: '400px',
        data: {},
      },
    },
    activeBy: [
      {
        path: `${CMS_BASE_PATH}/articles`,
        afterPaths: [], // any path
      },
    ],
    rootModule: RootModuleType.CMS,
    type: MenuType.HELP,
    accessRules: {
      [ProjectIs.UTMC]: [UserIs.INTERNAL, UserIs.CLIENT],
      [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL, UserIs.CLIENT],
      [ProjectIs.ACME]: [UserIs.INTERNAL, UserIs.CLIENT],
    },
  },
  {
    text: 'About',
    route: `${CMS_BASE_PATH}/about`,
    materialIcon: 'info_outline',
    activeBy: [
      {
        path: `${CMS_BASE_PATH}/about`,
        afterPaths: [],
      },
    ],
    rootModule: RootModuleType.CMS,
    type: MenuType.ABOUT,
    accessRules: [ProjectIs.UTMC],
  },
];
