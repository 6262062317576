export const CM_BASE_PATH = 'client-management';
export const CMS_BASE_PATH = 'dashboard';
export const CM_TITLE = 'Client Management';
export const CMS_TITLE = 'Case Management System';
export const REQUEST_AUTH_HEADER_KEY = 'Authorization';
export const SPECIAL_NOTE_MAX_LENGTH = 70;
export const SSN4_REGEX = /^\d{4}$/;
export const SSN_REGEX = /^\d{9}$/;
export const SSN_FORMATTED_REGEX = /^\d{3}-\d{2}-\d{4}$/;
export const CURRENCY_FORMAT_REGEX = /(\d)(?=(\d{3})+\.)/g;

export enum Statuses {
  CLIENT = 'CLIENT',
  REVIEW = 'REVIEW',
  QUEUED = 'QUEUED',
  STATE = 'STATE',
  CLOSED = 'CLOSED',
  REVIEW_PAST_DUE = 'REVIEW_PAST_DUE',
}

export enum DueDateQueues {
  PAST = 'past',
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  WEEK = 'week',
  TOTAL = 'total',
}

export const YES_OR_NO_OPTIONS = {
  true: 'Yes',
  false: 'No',
};

export const mediaTypes = {
  PDF: 'application/pdf',
  RTF: 'application/rtf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ODT: 'application/vnd.oasis.opendocument.text',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PNG: 'image/png',
  JPG: 'image/jpeg',
  TIFF: 'image/tiff',
};

export const mediaTypeExtensions = {
  'application/pdf': 'pdf',
  'application/rtf': 'rtf',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.oasis.opendocument.text': 'odt',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/octet-stream': 'xlsx',
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/tiff': 'tiff',
};

export const actionOptions = [
  { title: '1 - Informational (General)', value: '1' },
  { title: '2 - Informational (Claimant/Employer Specific)', value: '2' },
  { title: '3 - Requires Action (General)', value: '3' },
  { title: '4 - Requires Action (Adhoc Questions)', value: '4' },
  { title: '5 - Optional Fact-Finding', value: '5' },
];

export enum DocVisibility {
  INTERNAL = 'internal',
  EMPLOYER = 'client',
  STATE = 'state',
}

export const documentVisibilityOptions = [
  { title: 'Internal', value: DocVisibility.INTERNAL },
  { title: 'Employer', value: DocVisibility.EMPLOYER },
  { title: 'State', value: DocVisibility.STATE },
];

export const ERROR_MESSAGE = Object.freeze({
  TOO_LARGE_SIZE_OF_STATE_DOCUMENTS:
    'Total size of documents is too large. Please adjust the number of documents sent to State',
  ANALYST_SHOULD_BE_ASSIGNED: 'Analyst should be assigned',
});

export const MONTHS_NUMBER = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const INSURANCE_TYPE = {
  M: 'Merit-Rated',
  R: 'Reimbursable',
};

export const privilegedUserAccountType = {
  in_this_family: 'In this Family',
  this_account_only: 'This Account Only',
};

export const clientUserAccountType = {
  all_for_client: 'All',
  this_account_only: 'One',
};

export const ACCOUNT_TYPE = {
  IN_THIS_FAMILY: 'in_this_family',
  THIS_ACCOUNT_ONLY: 'this_account_only',
  ALL_FOR_CLIENT: 'all_for_client',
};

export const OUT_OF_SERVICE_OPTIONS = [
  {
    value: true,
    label: 'No',
  },
  {
    value: false,
    label: 'Yes',
  },
];

export const yesOrNoArrayOptions = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const PHONE_INPUT_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const FEIN_INPUT_MASK = [
  /[0-9]/, // First digit
  /[0-9]/, // Second digit
  '-', // Hyphen
  /[0-9]/, // Third digit
  /[0-9]/, // Fourth digit
  /[0-9]/, // Fifth digit
  /[0-9]/, // Sixth digit
  /[0-9]/, // Seventh digit
  /[0-9]/, // Eighth digit
  /[0-9]/, // Ninth digit
];

export const TEMPORARY_FEIN_INPUT_MASK = [
  /.*/, // First Symbol (any character)
  /[0-9]/, // First digit
  /[0-9]/, // Second digit
  '-', // Hyphen
  /[0-9]/, // Third digit
  /[0-9]/, // Fourth digit
  /[0-9]/, // Fifth digit
  /[0-9]/, // Sixth digit
  /[0-9]/, // Seventh digit
  /[0-9]/, // Eighth digit
  /[0-9]/, // Ninth digit
];

export const INTERNATIONAL_PHONE_INPUT_MASK = [
  '+',
  '1',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
