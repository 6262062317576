/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/no-extraneous-dependencies */
import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  NgZone,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { take } from 'rxjs/operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NumberMask, TextMask } from '../services/form-controls.service';

@Component({
  selector: 'lib-cm-textarea',
  templateUrl: './cm-textarea.component.html',
  styleUrls: ['./cm-textarea.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CmTextareaComponent),
      multi: true,
    },
  ],
})
export class CmTextareaComponent implements ControlValueAccessor, AfterViewInit {
  constructor(private ngZone: NgZone) {}

  val: string;

  isCurrencyMask: boolean;

  isManuallyResized: boolean = false;

  lastHeight: number = 0;

  onChange: (val) => {};

  onTouched: (val) => {};

  @Input() disabled: boolean;

  @Input() rows = 2;

  @Input() maxlength: number;

  @Input() isAutoHeight = false;

  @Input() clearable: boolean;

  @Input() highlightIfSet: boolean;

  @Input() placeholder = 'Enter Your Value';

  @Input() formControl: FormControl;

  mask: any[] | boolean | ((rawValue) => boolean | any[]) = this.createInputMask();

  @ViewChild('inputFef', { static: true }) inputElement: ElementRef;

  @ViewChild('cfcAutosize')
  contentFCAutosize: CdkTextareaAutosize;

  @Input() set value(val: string) {
    if (val !== undefined && val !== null && this.val !== val) {
      this.val = `${val}`;
      const rawVal = this.isCurrencyMask ? this.val.replace(/([$,.])/g, '') : this.val;
      if (this.onChange) {
        this.onChange(rawVal);
      }
      if (this.onTouched) {
        this.onTouched(rawVal);
      }
      this.resize();
    } else {
      this.val = '';
    }
  }

  get value() {
    return this.val;
  }

  @Input() set inputMask(value: any) {
    this.isCurrencyMask = false;
    this.mask = this.createInputMask({ ...value });
  }

  @Input() set currencyMask(value: NumberMask | boolean) {
    this.isCurrencyMask = true;
    this.mask = createNumberMask(typeof value === 'boolean' ? {} : { ...value });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.val = value;
    this.resize();
  }

  reset() {
    if (!this.disabled) {
      this.writeValue('');
      this.inputElement.nativeElement.children[0].focus();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  createInputMask(
    { maxLength = 60, disableStartingSpace = true }: TextMask = {
      maxLength: 255,
      disableStartingSpace: true,
    },
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (rawValue) => {
      let mask: boolean | any[] = false;
      if (maxLength) {
        mask = [];
        for (let i = 0; i < maxLength; i++) {
          mask[i] = /[\s\S]/;
        }
        if (disableStartingSpace) {
          mask[0] = /\S/;
        }
      }
      return mask;
    };
  }

  resize(): void {
    const txtArea = this.inputElement.nativeElement.children[0];
    if (!txtArea) return;

    if (this.isManuallyResized) {
      return;
    }

    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      txtArea.style.height = 'auto';

      txtArea.style.height = `${txtArea.scrollHeight + 2}px`;
    });
  }

  ngAfterViewInit(): void {
    const txtArea = this.inputElement.nativeElement.children[0];

    txtArea.addEventListener('mousedown', (event: MouseEvent) => {
      const isOnResizeHandle =
        event.offsetX > txtArea.offsetWidth - 10 || event.offsetY > txtArea.offsetHeight - 10;

      if (isOnResizeHandle) {
        this.isManuallyResized = true;
        this.lastHeight = txtArea.offsetHeight;
      }
    });

    txtArea.addEventListener('input', () => {
      if (this.isManuallyResized && txtArea.offsetHeight === this.lastHeight) {
        this.isManuallyResized = false;
        this.resize();
      }
    });
  }
}
